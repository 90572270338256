import axios from "axios";

const token = localStorage.getItem("zegeToken")

// if(!token) {
//     this.$router.push(`/login`);
// }

const Axios = axios.create({
    // baseURL: "https://pharmos.royscript.com/v1",
    baseURL: "http://localhost:4005/v1",
});

Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

export default Axios;