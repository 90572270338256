<template>
    <div class="d-flex">
        <div class="header-btn-lg pr-0">
            <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                    <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <img width="38" class="rounded-circle" src="@/assets/images/user.png" alt="">
                                </div>
                    <div class="widget-content-left">
                        <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-lg" variant="link" right>
                            <span slot="button-content">
                                <div class="widget-content-left  mr-3 header-user-info">
                                    <div class="widget-heading">{{ userDetails.user != null ? `${userDetails.user.firstName} ${userDetails.user.lastName}` : 'Unknown' }}</div>
                                    <!-- <div class="widget-subheading">VP People Manager</div> -->
                                </div>
                                
                                
                            </span>
                            <!-- <button type="button" tabindex="0" class="dropdown-item">Menus</button>
                            <button type="button" tabindex="0" class="dropdown-item">Settings</button>
                            <h6 tabindex="-1" class="dropdown-header">Header</h6> -->
                            <button type="button" @click="navigateToProfile" tabindex="0" class="dropdown-item"><a-icon type="user" class="mr-2" theme="outlined" /> Profile</button>
                            <button type="button" @click="navigateToPassword" tabindex="0" class="dropdown-item"><a-icon type="lock" class="mr-2" theme="outlined" /> Change Password</button>
                            <div tabindex="-1" class="dropdown-divider"></div>
                            <button type="button" @click="logoutUser" tabindex="0" class="dropdown-item"><a-icon type="logout" class="mr-2" theme="outlined"/> Logout</button>
                        </b-dropdown>
                    </div>
                    
                    <!-- <div class="widget-content-right header-user-info ml-3">
                        <b-btn v-b-tooltip.hover title="Tooltip Example" class="btn-shadow p-1" size="sm" variant="info">
                            <font-awesome-icon icon="calendar-alt" class="mr-1 ml-1"/>
                        </b-btn>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import VuePerfectScrollbar from 'vue-perfect-scrollbar'

    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faAngleDown,
        faCalendarAlt,
        faTrashAlt,
        faCheck,
        faFileAlt,
        faCloudDownloadAlt,
        faFileExcel,
        faFilePdf,
        faFileArchive,
        faEllipsisH,
    } from '@fortawesome/free-solid-svg-icons'
    // import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

    library.add(
        faAngleDown,
        faCalendarAlt,
        faTrashAlt,
        faCheck,
        faFileAlt,
        faCloudDownloadAlt,
        faFileExcel,
        faFilePdf,
        faFileArchive,
        faEllipsisH,
    );

    export default {
        // components: {
        //     'font-awesome-icon': FontAwesomeIcon,
        // },
        data() {

            return {
                userDetails: {
                    user: null,
                    company: null,
                }
            }
        },
        created() {
            this.getUserDetails();
        },
        methods: {
            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                if(userDetails != null) {
                    // eslint-disable-next-line no-console
						// console.log(userDetails)
                    this.userDetails.user = userDetails;
                    this.userDetails.company = userDetails.company;
                }else {
                    // this.$router.push(`/`);
                }
            },


            navigateToProfile() {
                this.$router.push('/user-management/profile');
            },

            navigateToPassword() {
                this.$router.push('/user-management/change-password');
            },

            async logoutUser() {
                await localStorage.setItem("zegeUser", null)
                await localStorage.setItem("zegeToken", null)

                this.$router.push(`/`);
            }
        }
    }


</script>
